import { Controller } from 'stimulus'
import Masonry from 'masonry-layout'

export default class extends Controller {

    static targets = [ 'container' ]

    initialize() {
        
    }

    connect () {
        var grid = document.querySelector('.cat-grid');
        if (grid) {
          var msnry = new Masonry(grid, {
            itemSelector: '.grid-item',
            transitionDuration: 0,
            gutter: 0,
            percentPosition: true
          });
        }
    }
    
    disconnect() {
        this.iso.destroy()
    }
}

