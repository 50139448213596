import '../css/app.scss'
import lazysizes from 'lazysizes' 
import Turbolinks from 'turbolinks'

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

Turbolinks.start()
const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))